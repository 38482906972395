body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
}

.card-container {
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    padding: 10px;
}

.card {
    position: relative;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
}

.word {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}

.definition {
    font-size: 18px;
    color: #555555;
    margin-top: 10px;
    text-align: center;
}

.power {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: #ff6347; /* Adjust color as needed */
    font-weight: bold;
}
.border-grey {
    border: 2px solid grey;
}

.border-green {
    border: 3px solid green;
}

.border-blue {
    border: 4px solid blue;
}

.border-purple {
    border: 6px solid purple;
}

.border-orange {
    border: 10px solid orange;
}
.gacha-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #f06, #ffba08);
}

.resource-gacha {
    font-size: 2rem;
    color: white;
    margin-bottom: 20px;
}

.gacha-button {
    padding: 20px 40px;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    background-color: #ff6f61;
    border: none;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 80%;
    max-width: 600px;
    text-align: center;
}

.gacha-button:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.gacha-button:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transform: scale(1); /* Removed scale */
    padding: 5vw; /* Added relative padding for the overlay */
}

.overlay .overlay-content {
    text-align: center;
    color: white;
    padding: 10vw; /* Use relative padding to adjust to screen size */
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    max-width: 90vw; /* Added max-width to make sure overlay fits on small screens */
}

.overlay .overlay-content h2 {
    font-size: 8vw; /* Relative font size */
    margin-bottom: 0;
}

/* The color overlays */
.overlay.border-grey {
    background-color: rgba(128, 128, 128, 0.9);
}

.overlay.border-green {
    background-color: rgba(0, 128, 0, 0.9);
}

.overlay.border-blue {
    background-color: rgba(0, 0, 255, 0.9);
}

.overlay.border-purple {
    background-color: rgba(128, 0, 128, 0.9);
}

.overlay.border-orange {
    background-color: rgba(255, 165, 0, 0.9);
}
