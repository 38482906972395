/* Reset css */

* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

a, a:link  {
    text-decoration: none;
}

a:hover  {
    text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

button {
  background: none;
  cursor: pointer;
}

h2, h3 {
  font-size: inherit;
  font-weight: 400;
}

/* ------------- */


:root {
  --bg: #f4f4f4;
  --text: #4d5059;
  --green: #34673e;
}

body {
    font-family: "Arial", sans-serif;
}

.container {
  min-width: 300px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  color: var(--text);
}

main {
  flex-grow: 1;
}

main > * {
  padding: 0 8px;
}

/* header */
.header {
  position: sticky;
  top: 0;
  padding: 0 16px;
  background-color: var(--green);
  padding-top: 6px;
  padding-bottom: 6px;
  color: white;
}

.header img {
  width: 24px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  align-items: center;
}

.header-top span {
  font-size: 18px;
}

.header-bottom {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}



/* Balance */
.balance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.balance__value {
  font-size: 32px;
}

.balance__text {
  color: grey;
  font-size: 14px;
}

.title-section {
  background-color: var(--green);
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
  color: white;
}

/* transaction */
.transaction {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.383);
}


.transaction-1 .transaction__title {
  margin-left: 40px;
}


.transaction__title {
  font-size: 18px;
  margin-right: auto;
}

.transaction__date {
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.transaction__data-day {
  font-size: 16px;
  font-weight: 600;
}

.transaction__data {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.transaction__value {
  font-size: 28px;

  sup {
    font-size: 14px;
  }
}

.transaction__balance {
  font-size: 12px;
}


/* footer */
.footer {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
  background-color: white;

  button {
    padding: 8px;
  }

  button.active {
    border-top: 3px solid var(--green);
  }

  img {
    height: 32px;
    box-sizing: content-box;
  }
}
.collect-button {
    background-color: green;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
}

.collect-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}
.transaction.done {
    background-color: grey; /* Background color for completed quests */
}